import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import ContentRenderer from "components/ContentRenderer/ContentRenderer";

const Page = ({ data, pageContext }) => {
  const seo = data.datoCmsPage.seoMetaTags;
  const blocks = data.datoCmsPage.content;

  return (
    <>
      <HelmetDatoCms seo={seo} />
      <ContentRenderer blocks={blocks} />
    </>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($title: String!) {
    datoCmsPage(title: { eq: $title }) {
      id: originalId
      title
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      navbarColors
      content {
        ...AccordionImage
        ...BookADemo
        ...FAQ
        ...FeatureOverview
        ...FormReference
        ...FullscreenHero
        ...FullscreenPopHero
        ...ImageGrid
        ...Ipad
        ...List
        ...MultipleModule
        ...OpenPositions
        ...PartnerPricingReference
        ...PricingReference
        ...PublicationGrid
        ...RichText
        ...RichTextTwoCol
        ...StandardHero
        ...TableReference
        ...TestimonialCarousel
        ...TextImage
        ...TitleBox
        ...TitleColumns
        ...TitleQuote
        ...TitleSubtitle
        ...TitleText
        ...TrustedBy
        ...USPReference
        ...VideoPlayer
        ...HomepageSection
        ...HeroTextImage
        ...QuickInfo
      }
    }
  }
`;